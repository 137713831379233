
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ColumnConfig } from './types'

@Component
export default class DataTable extends Vue {
  @Prop() tableData!: any;
  @Prop() column!: Array<ColumnConfig>;
  @Prop({ type: Number, default: 0 }) total!: number;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  /**
 * @description 自定义渲染表格列
 */
  renderTableColumn () {
    return this.column.map((item) => {
      return (
        <el-table-column
          label={item.label}
          align="center"
          scopedSlots={{
            default: (scope: any) => {
              if (item.scopedSlots) {
                /* 此处采用了插槽来渲染 */
                return this.$scopedSlots[item.scopedSlots]!(scope.row)
              } else if (item.key === 'action') {
                /* 渲染操作列 */
                return (
                  <div class="action">
                    {this.renderTableColumnAction(item.options, scope.row)}
                  </div>
                )
              } else {
                /* 默认的渲染方式 */
                return <span>{scope.row[item.code!]}</span>
              }
            }
          }}
        ></el-table-column>
      )
    })
  }

  /**
   * @description 渲染操作列
   */
  renderTableColumnAction (options: any, row: any): HTMLElement {
    return options.map((item: any) => {
      return (
        <span
          onClick={() => item.click(row)}
          style={{ color: item.color || '#305CDE' }}
        >
          {item.title}
        </span>
      )
    })
  }

  /**
   * @description 渲染分页
   */
  renderPagination () {
    if (!this.total) {
      return
    }
    return (
      <el-pagination
        layout="prev, pager, next"
        total={this.total}
        style={{ float: 'right', marginTop: '20px' }}
        on-current-change={(size:number) => {
          this.$emit('changePagination', size)
        }}
      ></el-pagination>
    )
  }

  /**
   * @description 渲染表格
   */
  render () {
    return (
      <div>
        <el-table
          v-loading={this.loading}
          data={this.tableData}
          style="width: 100%"
          header-cell-style={{ background: '#eef1f6', color: '#606266' }}
        >
          {this.renderTableColumn}
        </el-table>
        { this.renderPagination()}
      </div>
    )
  }
}
