























import { Vue, Component, Watch } from 'vue-property-decorator'
import logo from '@/assets/imgs/common/logo.png'
import scssexport from '@/assets/scss/index.scss'
import routes from '@/router/router'
import { Route } from 'vue-router'
import { State } from 'vuex-class'

@Component
export default class Menu extends Vue {
  logo: string = logo;
  scssexport: any = scssexport;
  routes = routes;
  activeMenuIndex = '';
  menuOpeneds = [];
  @State(state => state.system.systemTitle) systemTitle!: string;

  @Watch('$route', { immediate: true })
  changeRoute (value: Route): void {
    this.activeMenuIndex = value.fullPath
  }

  toIndex (): void {
    this.$router.push('/index')
    this.menuOpeneds = []
    this.activeMenuIndex = '/index'
  }
}
