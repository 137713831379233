























import { Vue, Component, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import dlam from '@/assets/imgs/common/am.png'
import { State } from 'vuex-class'
interface CurrentRoute {
  title: string;
  path: string;
}
@Component
export default class navBar extends Vue {
  @State((state) => JSON.parse(state.user.userInfo)) userInfo!: unknown;

  circleUrl = dlam;

  currentRoute: Array<CurrentRoute> = [];

  @Watch('$route')
  changeRoute (value: Route): void {
    const currentRoute: Array<CurrentRoute> = value.matched
      .filter((item) => {
        return item.path && item.path !== '/index'
      })
      .map((i) => {
        return {
          title: i.meta.title,
          path: i.path
        }
      })
    this.currentRoute = currentRoute
  }

  handleCommand (command: string): void {
    switch (command) {
      case 'logout':
        localStorage.clear()
        this.$router.push('/login')
        break
      default:
        break
    }
  }
}
