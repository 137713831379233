import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import db from '@/utils/db'
import { SystemState } from '../module-types'
import { RootState } from '../root-types'
Vue.use(Vuex)

const system:Module<SystemState, RootState> = {
  namespaced: true,
  state: {
    systemTitle: process.env.VUE_APP_SYSTEM_TITLE,
    token: db.get('token')
  },
  mutations: {
    setToken (state: SystemState, value: string):void {
      state.token = value
      db.set('token', value)
    }
  }
}
export default system
