/*
 * @Author: your name
 * @Date: 2022-02-11 11:13:31
 * @LastEditTime: 2022-02-11 16:11:01
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\components\table\index.ts
 */
import { VueConstructor } from 'vue'
import DataTable from './index.vue'

export default {
  install: (Vue:VueConstructor):void => {
    /* TODO:这里组件的名字思考采用其他形式改写 */
    Vue.component('DataTable', DataTable)
  }
}
