const get = (key: string): string | null => {
  return window.localStorage.getItem(key)
}

const set = (key: string, value: string): void => {
  window.localStorage.setItem(key, value)
}

const clearAll = ():void => {
  window.localStorage.clear()
}

export default {
  get,
  set,
  clearAll
}
