/*
 * @Author: your name
 * @Date: 2022-02-11 11:13:55
 * @LastEditTime: 2022-02-11 15:18:50
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \idle_items_web\src\router\router.ts
 */
export interface MenuConfig {
  title: string;
  path: string;
  component: string;
  isShowInMenu?:boolean
  icon?: string;
  children?: Array<MenuConfig>;
}
const routers: Array<MenuConfig> = [
  {
    title: '首页',
    path: 'index',
    icon: 'el-icon-s-home',
    component: 'views/Home'
  },
  {
    title: '校园管理',
    path: 'community',
    icon: 'el-icon-office-building',
    component: 'views/layout/common/index',
    children: [
      {
        title: '已开通',
        path: 'pass',
        component: 'views/community/pass/index'
      },
      {
        title: '未开通',
        path: 'notpass',
        component: 'views/community/notpass/index'
      }
    ]
  },
  {
    title: '用户管理',
    path: 'account',
    icon: 'el-icon-user',
    component: 'views/account/index'
  },
  {
    title: '用户详情',
    path: 'accountinfo',
    isShowInMenu: false,
    icon: 'el-icon-user',
    component: 'views/account/more'
  },
  {
    title: '用户反馈',
    path: 'feedback',
    icon: 'el-icon-bell',
    component: 'views/feedback/index'
  }
]
export default routers
