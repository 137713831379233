import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import element from '@/element-install'
import { Get, Post, Delete } from '@/request/index'
import Api from '@/request/api'
import table from '@/components/table/index'

Vue.config.productionTip = false

Vue.prototype.$Get = Get
Vue.prototype.$Post = Post
Vue.prototype.$Delete = Delete
Vue.prototype.$Api = Api

Vue.use(element)
Vue.use(table)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 定义ts避免报错
declare module 'vue/types/vue' {
    interface Vue {
        $Get: typeof Get
        $Post: typeof Post
        $Delete:typeof Delete
        $Api: typeof Api
    }
}
