import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import db from '@/utils/db'
import { UserState } from '../module-types'
import { RootState } from '../root-types'
Vue.use(Vuex)

const user:Module<UserState, RootState> = {
  namespaced: true,
  state: {
    userInfo: db.get('userInfo')
  },
  mutations: {
    setUserInfo (state: UserState, value: string):void {
      state.userInfo = value
      db.set('userInfo', value)
    }
  }
}
export default user
