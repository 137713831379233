import axios, { AxiosInstance } from 'axios'
import db from '@/utils/db'
import { Message, Loading, Notification } from 'element-ui'
import { ElLoadingComponent } from 'element-ui/types/loading'
import { ElNotificationComponent } from 'element-ui/types/notification'
import router from '@/router/index'

let loadingInstance: ElLoadingComponent
let notify: ElNotificationComponent | null
interface requestConfig {
  showMessage?: boolean;
  loading?: boolean;
  target?: string | HTMLElement;
  needAll?: boolean;
}
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    Authorization: ''
  }
})
/* 请求拦截 */
axiosInstance.interceptors.request.use((config) => {
  config.headers!.Authorization = db.get('token') || ''
  return config
})

axiosInstance.interceptors.response.use(
  (responce) => {
    const { data } = responce
    if (data.code === -6) {
      notify = notify
        ? null
        : Notification({
          title: '登录过期',
          message: '3秒后将自动跳转至登录界面！！',
          type: 'warning',
          duration: 3000
        })
      setTimeout(() => {
        db.clearAll()
        router.replace('/login')
      }, 3000)
    }
    return responce
  },
  (error) => {
    return Promise.reject(error)
  }
)

const Get = async (url: string, data?: unknown, config?: requestConfig) => {
  if (config?.loading === true) {
    loadingInstance = Loading.service({
      fullscreen: true,
      target: config.target
    })
  }
  const res = await axiosInstance.get<{ code: number; data: any; msg: string }>(
    url,
    {
      params: data
    }
  )
  if (res.data.code === 1) {
    loadingInstance && loadingInstance.close()
    return Promise.resolve(res.data)
  } else {
    Message.error(res.data.msg)
    loadingInstance && loadingInstance.close()
    return Promise.reject(res.data.msg)
  }
}
const Post = async (url: string, data?: unknown, config?: requestConfig) => {
  if (config?.loading === true) {
    loadingInstance = Loading.service({
      fullscreen: true,
      target: config.target
    })
  }
  const res = await axiosInstance.post<{
    code: number;
    data: any;
    msg: string;
  }>(url, data)
  if (res.data.code === 1) {
    if (config?.showMessage === true) {
      Message.success(res.data.msg)
    }
    loadingInstance && loadingInstance.close()
    if (config?.needAll) {
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res.data)
    }
  } else {
    Message.error(res.data.msg)
    loadingInstance && loadingInstance.close()
    return Promise.reject(res.data.msg)
  }
}
const Delete = async (url: string, data?: unknown) => {
  const res = await axiosInstance.delete<{
    code: number;
    data: any;
    msg: string;
  }>(url, {
    params: data
  })
  if (res.data.code === 1) {
    return Promise.resolve(res.data)
  } else {
    Message.error(res.data.msg)
    return Promise.reject(res.data.msg)
  }
}
export { Get, Post, Delete }
