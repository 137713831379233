

















import { Vue, Component } from 'vue-property-decorator'
import NavBar from './navbar/index.vue'
import AsideMenu from './asidemenu/index.vue'
@Component({
  components: {
    NavBar,
    AsideMenu
  }
})
export default class Demo extends Vue {}
