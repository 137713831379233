import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import system from './system'
import user from './user'
import { RootState } from './root-types'
Vue.use(Vuex)

const store:StoreOptions<RootState> = {
  modules: {
    system,
    user
  }
}

export default new Vuex.Store(store)
