import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import routers, { MenuConfig } from './router'
import layout from '@/views/layout/index.vue'
import store from '@/store'

/* 避免重复调用push方法时报错 */
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location:string) {
  // eslint-disable-next-line
  return (originalPush.call(this, location) as any).catch((err:unknown) => err)
}

Vue.use(VueRouter)

/* 递归遍历json */
const changeRoutes = (T:Array<MenuConfig>):Array<RouteConfig> => {
  return T.map(item => {
    return {
      path: item.path,
      component: () => import(`@/${item.component}.vue`),
      meta: {
        title: item.title
      },
      redirect: item.children && item.path + '/' + item.children[0].path,
      children: item.children && changeRoutes(item.children)
    }
  })
}

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/',
    name: 'layout',
    redirect: '/index',
    component: layout,
    children: changeRoutes(routers)
  }

]

/* 路由白名单 */
const whiteList = ['/login']

const router = new VueRouter({
  routes
})

/* 路由拦截 */
router.beforeEach((to, from, next) => {
  if (!store.state.system.token && whiteList.indexOf(to.path) === -1) {
    next('/login')
  } else {
    next()
  }
})

export default router
